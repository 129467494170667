const CONFIGURATION = {
    authorizedDomains: ['localhost'],
    defaultTheme: 'pasa-dark',
    apps: {
        mipasa: {
            enabled: false,
        },
        unbounded: {
            enabled: false,
        },
        papillon: {
            enabled: false,
        },
        crypto: {
            enabled: true,
        },
        dpasa: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: false,
    },
};
export default CONFIGURATION;
