export const IS_DEVELOPMENT = window.location.origin.includes('localhost');
export function getDefaultEnvironmentConfig() {
    return {
        environmentId: IS_DEVELOPMENT ? LOCAL_ENV : 'production',
    };
}
let ENVIRONMENT_CONFIG = getDefaultEnvironmentConfig();
export function updateEnvironmentConfig(newConfig) {
    if (newConfig.environmentId === 'local') {
        return;
    }
    ENVIRONMENT_CONFIG = { ...ENVIRONMENT_CONFIG, ...newConfig };
}
export const getEnvironmentConfig = () => ENVIRONMENT_CONFIG;
const isCrypto = (win) => win.location.hostname.indexOf('pasa.network') !== -1;
const isMiPasa = (win) => win.location.hostname.indexOf('mipasa.com') !== -1;
const isDPasa = (win) => win.location.hostname.indexOf('kalk.ai') !== -1;
export const isCryptoProduction = (win) => isCrypto(win) &&
    win.location.hostname.indexOf('staging') === -1 &&
    win.location.hostname.indexOf('test') === -1 &&
    win.location.hostname.indexOf('dev') === -1;
export const isMiPasaProduction = (win) => isMiPasa(win) &&
    win.location.hostname.indexOf('staging') === -1 &&
    win.location.hostname.indexOf('qa') === -1 &&
    win.location.hostname.indexOf('test') === -1 &&
    win.location.hostname.indexOf('dev') === -1;
export const isDPasaProduction = (win) => isDPasa(win) &&
    win.location.hostname.indexOf('staging') === -1 &&
    win.location.hostname.indexOf('test') === -1 &&
    win.location.hostname.indexOf('dev') === -1;
export const isProduction = (win) => isMiPasaProduction(win) || isCryptoProduction(win) || isDPasaProduction(win);
export const isPrimaryInstance = (win) => win.location.hostname === 'app.mipasa.com' || win.location.hostname === 'app.pasa.network';
export const isStaging = (win) => isMiPasa(win) &&
    (win.location.hostname.indexOf('staging') !== -1 ||
        win.location.hostname.indexOf('test') !== -1 ||
        win.location.hostname.indexOf('dev') !== -1 ||
        win.location.hostname.indexOf('qa') !== -1);
