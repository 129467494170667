const CONFIGURATION = {
    authorizedDomains: ['localhost'],
    defaultTheme: 'dpasa-dark',
    apps: {
        dpasa: {
            enabled: true,
            url: 'https://app.kalk.ai',
        },
        mipasa: {
            enabled: false,
        },
        unbounded: {
            enabled: false,
        },
        papillon: {
            enabled: false,
        },
        crypto: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: false,
    },
    token: {
        tokenAddress: '0xc551b009C1CE0b6efD691E23998AEFd4103680D3',
        vestingAddress: '0xB00ec1Fa8728791B60c095793D21EEC11bE47E0E',
        internalAddress: '0xe2abD1E57c8D6f0D21645a8a0Af6C4251a4395f5',
        zkBridgeHubAddress: '0x01146571bdcc6ddcb2f6ae9222231b0d7d8de532',
        zkBridgeAddress: '0x13b839215a2c292c604e563c07d282a2daf6a6ac',
        name: 'BOUND',
        decimals: 18,
        unitRatio: 1,
        chain: 'devnet',
        zkChain: 'devnet_zk',
    },
};
export default CONFIGURATION;
