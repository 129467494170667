import * as Sentry from '@sentry/browser';
import { Buffer } from 'buffer';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import getApplicationConfig from '~platform';
import { getDefaultEnvironmentConfig, isCryptoProduction, isDPasaProduction, isMiPasaProduction, updateEnvironmentConfig, } from '~utils/environment';
import IndexLoader from './index-loader';
// Very ugly hack to inject global Buffer dependency for WalletConnect
window.Buffer = Buffer;
const getAppConfigFromServer = async () => {
    try {
        return (await fetch(`/app.json`).then(f => f.json()));
    }
    catch (e) {
        return getDefaultEnvironmentConfig();
    }
};
const initializeSentry = (config) => {
    if (!config.sentry.enabled) {
        return;
    }
    Sentry.init({
        dsn: config.sentry.dsn,
        integrations: [Sentry.browserTracingIntegration()],
        environment: config.sentry.environment,
        tunnel: config.sentry.tunnel,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.05,
    });
};
export const initializeGoogleTag = () => {
    let tag = '';
    if (isMiPasaProduction(window)) {
        tag = 'G-MJ6SR09B0X';
    }
    else if (isCryptoProduction(window)) {
        tag = 'G-WK0G0WSWKK';
    }
    else if (isDPasaProduction(window)) {
        tag = 'G-SWNLFKMX9Q';
    }
    else {
        return;
    }
    const newScript = document.createElement('script');
    newScript.setAttribute('type', 'text/javascript');
    newScript.setAttribute('async', 'async');
    newScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${encodeURIComponent(tag)}`);
    window.dataLayer = window.dataLayer || [];
    function gtag(..._params) {
        // GTag expects legacy arguments object here. this is not the same as "params". "params" is left for TS compat.
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', tag);
    window.gtag = gtag;
    document.head.appendChild(newScript);
};
// This function checks for URL change and sends those to the trackers we are using.
const initializeTracking = () => {
    const { gtag } = window;
    let lastURL = `${window.location.pathname}${window.location.search}`;
    if (gtag) {
        gtag({
            event: 'virtualPageview',
            pageUrl: window.location.href,
            pageTitle: document.title,
        });
    }
    // HubSpot API is very old and still doesn't track SPAs automatically.
    // We need to manually tell it that used navigated from one page to another.
    // UPD: This seems to happen to Google Analytics as well.
    const checkAndTrack = () => {
        const newURL = `${window.location.pathname}${window.location.search}`;
        if (newURL !== lastURL) {
            lastURL = newURL;
            if (gtag) {
                gtag({
                    event: 'virtualPageview',
                    pageUrl: window.location.href,
                    pageTitle: document.title,
                });
            }
        }
        window.requestAnimationFrame(checkAndTrack);
    };
    checkAndTrack();
};
window.addEventListener('DOMContentLoaded', async () => {
    ReactDOM.render(React.createElement(IndexLoader, null), document.getElementById('spa-container'));
    const config = await getAppConfigFromServer();
    updateEnvironmentConfig(config);
    // render it again, now with correct theme.
    ReactDOM.render(React.createElement(IndexLoader, null), document.getElementById('spa-container'));
    const AppRoutes = (await import('./routes')).default;
    ReactDOM.render(React.createElement(AppRoutes, null), document.getElementById('spa-container'));
    initializeGoogleTag();
    initializeSentry(getApplicationConfig());
    initializeTracking();
});
